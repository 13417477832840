import React from "react"

import { DMIcon } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { contrastColor } from "../DMTokenPreview/utils/token-utils"

type DMBadgeColors =
  | "primary"
  | "positive"
  | "critical"
  | "upgrade"
  | "neutral"
  | "blue"
  | "cyan"
  | "fuchsia"
  | "green"
  | "grey"
  | "lightGrey"
  | "orange"
  | "pink"
  | "purple"
  | "red"
  | "teal"
  | "yellow"

/**
 * This tries to resolve a custom color and assign to it a badge color
 * Will be removed when backend supports preset colors by default
 * @param color
 */
export function asBadgeColor(color: string): DMBadgeColors | boolean {
  switch (color) {
    case "#EDF5FF":
      return "blue"
    case "#D5FBFE":
      return "cyan"
    case "#FCF0FF":
      return "fuchsia"
    case "#EBFEF6":
      return "green"
    case "#EDEFF3":
      return "grey"
    case "#F6F7F9":
      return "lightGrey"
    case "#FFF2E1":
      return "orange"
    case "#FDF0F7":
      return "pink"
    case "#F5F2FD":
      return "purple"
    case "#FEF1F2":
      return "red"
    case "#DFFDF6":
      return "teal"
    case "#FEF9C3":
      return "yellow"
    default:
      return false
  }
}

export type DMBadgeProps = {
  children: React.ReactNode
  size?: "small" | "medium"
  color?: DMBadgeColors | string
  truncate?: boolean
  className?: string
  icon?: React.ReactElement | React.ComponentType
}

export function DMBadge(props: DMBadgeProps) {
  const {
    children,
    size = "small",
    truncate = false,
    color = "neutral",
    className,
    icon,
  } = props

  const presetColors = {
    // semantic
    primary: "bg-primary-faded text-primary inner-border-primary-faded",
    positive: "bg-positive-faded text-positive inner-border-positive-faded",
    critical: "bg-critical-faded text-critical inner-border-critical-faded",
    upgrade: "bg-upgrade-faded text-upgrade inner-border-upgrade-faded",
    neutral: "bg-neutral-faded text-neutral-faded inner-border-neutral-faded",
    // decorative
    blue: "bg-decorative-blue text-decorative-blue inner-border-decorative-blue",
    cyan: "bg-decorative-cyan text-decorative-cyan inner-border-decorative-cyan",
    fuchsia: "bg-decorative-fuchsia text-decorative-fuchsia inner-border-decorative-fuchsia",
    green: "bg-decorative-green text-decorative-green inner-border-decorative-green",
    grey: "bg-decorative-grey text-decorative-grey inner-border-decorative-grey",
    lightGrey: "bg-decorative-light-grey text-decorative-light-grey inner-border-decorative-light-grey",
    orange: "bg-decorative-orange text-decorative-orange inner-border-decorative-orange",
    pink: "bg-decorative-pink text-decorative-pink inner-border-decorative-pink",
    purple: "bg-decorative-purple text-decorative-purple inner-border-decorative-purple",
    red: "bg-decorative-red text-decorative-red inner-border-decorative-red",
    teal: "bg-decorative-teal text-decorative-teal inner-border-decorative-teal",
    yellow: "bg-decorative-yellow text-decorative-yellow inner-border-decorative-yellow",
  }

  const customColor = () => {
    if (!(color in presetColors) && !asBadgeColor(color)) {
      return color
    }

    return null
  }

  const presetColorResolution = () => {
    if (asBadgeColor(color)) {
      return presetColors[asBadgeColor(color) as DMBadgeColors]
    }
    return presetColors[color as DMBadgeColors]
  }

  const getCustomColorForeground = () => {
    if (customColor()) {
      return contrastColor(color) === "light" ? "text-white" : "text-black"
    }
    return null
  }

  return (
    <div
      className={cn(
        "text-body-small inner-border inner-border-decorative rounded-small inline-flex font-semibold",
        size === "medium" ? "px-8 py-4" : "px-4 py-2",
        icon && "gap-4",
        icon && size === "medium" && "pl-4",
        icon && size === "small" && "pl-2 gap-2",
        truncate && "truncate overflow-ellipsis",
        customColor() ? getCustomColorForeground() : presetColorResolution(),
        className
      )}
      style={{ backgroundColor: customColor() || undefined }}
    >
      {icon && <DMIcon svg={icon} />}
      {children}
    </div>
  )
}
